<template>
    <div :style="{'background': `url(${backgroundUrl})`, 'background-size': 'auto' ,height: '100%'}">
        <Linnapea_app v-if="$route.meta?.m2ng === 'linnapea'" />
        <Metsavend_app v-else-if="$route.meta?.m2ng === 'metsavend'" />
    </div>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
import mainFuncV3 from '@/mainFuncV3'
import { toRefs } from "vue";
import { watch } from "vue";
import { ref } from "vue";
// import Harju_app from "./harjumaa/Harju_app.vue";
const Metsavend_app = defineAsyncComponent(() => import('./metsavend/Metsavend_app.vue'));
const Linnapea_app = defineAsyncComponent(() => import('./components/m2ng/Linnapea_app.vue'));
const {route} = mainFuncV3();
const metadata = toRefs(route);
const backgroundUrl = ref(require('../public/metsavend_background.jpg'));
watch(() => metadata.meta.value, (newvalue , oldvalue) => {
  if (newvalue !== oldvalue) {
      backgroundUrl.value = newvalue?.m2ng === 'linnapea' ? require('../public/linnapeataust.jpg') : require('../public/metsavend_background.jpg'); 
  }
})
</script>
<style>

:root{
  --sinuandmed_link : ligthrey;
}

#app {
  /* background: url("v-bind(backgroundUrl)") #2c3e50; */
  /* background: url("https://www.metsavend.ee/metsavend_background.jpg") #2c3e50; */
  
  background-size: auto;
  font-family: Arial;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 0px;
  height: 100%;
  padding-bottom: 50px;
}

body {
  background: #2D2E31;
  --van-white: #fff;
  --van-blue: #1989fa;
  --van-button-primary-color: var(--van-white);
  --van-button-primary-background: var(--van-primary-color);
}
</style>
