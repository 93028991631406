import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default function function_v3(){
    const data = ref();
    const data_loaded = ref(false);
    const islogged = ref(0);
    const dev_mode = computed(() => process.env.NODE_ENV  === 'development')
    const is_dev_ip = ref(false);
    const router = useRouter();
    const route = useRoute();

    return {
        data, data_loaded, islogged, dev_mode, is_dev_ip, router, route
    }
}