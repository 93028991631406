
import { createRouter, createWebHashHistory } from 'vue-router'

function lazyLoad(view){
    if(view.split('_')[0] == 'Harjumaa'){
        return() => import(`@/harjumaa/${view}.vue`)
    }
    else if(view.split('_')[0] == 'M2ng'){
        return() => import(`@/components/m2ng/${view}.vue`)
    }
    else{
        return() => import(`@/components/${view}.vue`)
    }
}

export default new createRouter({
    history: createWebHashHistory(),
    base: __dirname,
    routes: [

        //Linnapea urlid

        { path: '/Linnapea/', component: lazyLoad('PeaLeht'), meta:{m2ng:'linnapea'}},
        { path: '/Linnapea/Registreeru', component: lazyLoad('RegNewUser'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Avaleht', component: lazyLoad('M2ng_Avaleht'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Pank', component: lazyLoad('M2ng_Pank'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Aktsia', component: lazyLoad('M2ng_Aktsia'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Haltuura', component: lazyLoad('M2ng_Haltuura'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Turva', component: lazyLoad('M2ng_Turva'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Maffia', component: lazyLoad('M2ng_Maffia'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Jousaal', component: lazyLoad('M2ng_Jousaal'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Sinulinn', component: lazyLoad('M2ng_Sinulinn'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Turg', component: lazyLoad('M2ng_Turg'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Riik', component: lazyLoad('M2ng_Riik'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Riigid', component: lazyLoad('M2ng_Riigid'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Edetabel', component: lazyLoad('M2ng_Edetabel'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Aruanne', component: lazyLoad('M2ng_Aruanne'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/MinuAndmed', component: lazyLoad('M2ng_MinuAndmed'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Kutsumislink', component: lazyLoad('PeaLeht'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Reeglid', component: lazyLoad('PeaLeht'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Unustasidparooli', component: lazyLoad('PeaLeht'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Krediit', component: lazyLoad('M2ng_Krediit'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Maksud', component: lazyLoad('M2ng_Maksud'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Teated', component: lazyLoad('M2ng_Teated'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/KKK', component: lazyLoad('M2ng_KKK'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Foorum', component: lazyLoad('M2ng_Foorum'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Postkast', component: lazyLoad('M2ng_Postkast'), meta:{m2ng:'linnapea'} },
        { path: '/Linnapea/Adminpaneel', component: lazyLoad('M2ng_Adminpaneel'), meta:{m2ng:'linnapea'} },
        // // { path: "/:pathMatch(.*)*", component: lazyLoad('PeaLeht') },

        // //Harjumaa urlid

        // {path:'/Harjumaa/', component:lazyLoad('Harjumaa_Homepage_outside')},
        // {path:'/Harjumaa/Avaleht', component: () => import('./harjumaa/m2ng/Harjumaa_Avaleht.vue'), meta:{m2ng:'Harjumaa'}},
        // {path:'/Harjumaa/SinuMaja', component: () => import('./harjumaa/m2ng/Harjumaa_SinuMaja.vue'), meta:{m2ng:'Harjumaa'}},
  
        //Metsavenna urlid
        { path: "/:pathMatch(.*)*",component: () => import("@/metsavend/Metsavend_pealeht.vue"), meta:{m2ng:'metsavend',title:'Pealeht'}},
        {path:'/Metsavend', component: () => import("@/metsavend/Metsavend_pealeht.vue"), meta:{m2ng:'metsavend',title:'Pealeht'}},
        {path:'/Metsavend/Avaleht', component: () => import("@/metsavend/m2ng/Metsavend_avaleht.vue"), meta:{m2ng:'metsavend',title:'Avaleht'}},
        {path:'/Metsavend/Mets', component: () => import("@/metsavend/m2ng/Metsavend_mets.vue"), meta:{m2ng:'metsavend',title:'Mets'}},
        {path:'/Metsavend/Kaevandus', component: () => import("@/metsavend/m2ng/Metsavend_kaevandus.vue"), meta:{m2ng:'metsavend',title:'Kaevandus'}},
        {path:'/Metsavend/SinuMaja', component: () => import("@/metsavend/m2ng/Metsavend_Sinumaja.vue"), meta:{m2ng:'metsavend',title:'Sinumaja'}},
        {path:'/Metsavend/Ladu', component: () => import("@/metsavend/m2ng/Metsavend_Ladu.vue"), meta:{m2ng:'metsavend',title:'Ladu'}},
        {path:'/Metsavend/Kelder', component: () => import("@/metsavend/m2ng/Metsavend_kelder.vue"), meta:{m2ng:'metsavend',title:'Kelder'}},
        {path:'/Metsavend/Varustus', component: () => import("@/metsavend/m2ng/Metsavend_varustus.vue"), meta:{m2ng:'metsavend',title:'Varustus'}},
        {path:'/Metsavend/Kasitoo', component: () => import("@/metsavend/m2ng/Metsavend_kasitoo.vue"), meta:{m2ng:'metsavend',title:'Kasitoo'}},
        {path:'/Metsavend/Sulatamine', component: () => import("@/metsavend/m2ng/Metsavend_sulatamine.vue"), meta:{m2ng:'metsavend',title:'Sulatamine'}},
        {path:'/Metsavend/Sepandus', component: () => import("@/metsavend/m2ng/Metsavend_sepandus.vue"), meta:{m2ng:'metsavend',title:'Sepandus'}},
        {path:'/Metsavend/Varastamine', component: () => import("@/metsavend/m2ng/Metsavend_varastamine.vue"), meta:{m2ng:'metsavend',title:'Varastamine'}},
        {path:'/Metsavend/Joogimeister', component: () => import("@/metsavend/m2ng/Metsavend_joogimeister.vue"), meta:{m2ng:'metsavend',title:'Joogimeister'}},
        {path:'/Metsavend/Vaariskivi', component: () => import("@/metsavend/m2ng/Metsavend_vaariskivi.vue"), meta:{m2ng:'metsavend',title:'Vaariskivi'}},
        {path:'/Metsavend/Kalastamine', component: () => import("@/metsavend/m2ng/Metsavend_fishing.vue"), meta:{m2ng:'metsavend',title:'Kalastamine'}},
        {path:'/Metsavend/Pood', component: () => import("@/metsavend/m2ng/Metsavend_shop.vue"), meta:{m2ng:'metsavend',title:'Pood'}},
        {path:'/Metsavend/Registreeri', component: () => import("@/metsavend/m2ng/Metsavend_reg.vue"), meta:{m2ng:'metsavend',title:'Registreeru'}},
        {path:'/Metsavend/Reeglid', component: () => import("@/metsavend/m2ng/Metsavend_rules.vue"), meta:{m2ng:'metsavend',title:'Reeglid'}},
        {path:'/Metsavend/Unustasidparooli', component: () => import("@/metsavend/m2ng/Metsavend_LostPassword.vue"), meta:{m2ng:'metsavend',title:'Unustasid parooli'}},
        // {path:'/Metsavend/Krediit', component: () => import("@/metsavend/m2ng/Metsavend_Krediit.vue"), meta:{m2ng:'metsavend',title:'Krediit'}},
        {path:'/Metsavend/Aiandus', component: () => import("@/metsavend/m2ng/Metsavend_Aiandus.vue"), meta:{m2ng:'metsavend',title:'Aiandus'}},
        {path:'/Metsavend/Edetabel', component: () => import("@/metsavend/m2ng/Metsavend_Edetabel.vue"), meta:{m2ng:'metsavend',title:'Edetabel'}},
        {path:'/Metsavend/Ylesanded', component: () => import("@/metsavend/m2ng/Metsavend_ylesanded.vue"), meta:{m2ng:'metsavend',title:'Ylesanded'}},
        {path:'/Metsavend/Baar', component: () => import("@/metsavend/m2ng/Metsavend_baar.vue"), meta:{m2ng:'metsavend',title:'Baar'}},
        {path:'/Metsavend/Seaded', component: () => import("@/metsavend/m2ng/Metsavend_seaded.vue"), meta:{m2ng:'metsavend',title:'Seaded'}},
        {path:'/Metsavend/Credit', component: () => import("@/metsavend/m2ng/MetsavendCredit.vue"), meta:{m2ng:'metsavend',title:'Krediit'}},
        {path:'/Metsavend/Mailbox', component: () => import("@/metsavend/m2ng/MetsavendMailbox.vue"), meta:{m2ng:'metsavend',title:'Postkast'}},
        {path:'/Metsavend/MarketNotification', component: () => import("@/metsavend/m2ng/MetsavendMarketNotification.vue"), meta:{m2ng:'metsavend',title:'Turu teated'}},
        {path:'/Metsavend/Baarid', component: () => import("@/metsavend/m2ng/MetsavendBars.vue"), meta:{m2ng:'metsavend',title:'Baarid'}},

    ],
})
